
<section>
    <div class="img11">
      <div class="filtroNaranja1">
        <h1 class="centrado4 rounded nombre1  "><strong>ALL TICKETS</strong></h1>
  
      </div>
    </div>
  </section>
  
<section class="container mt-4">

    
    <p>Gracias por preferir All Tickets</p>
</section>
