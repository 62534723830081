import { LoginComponent } from './../login/login.component';
import { RegistrarseComponent } from './../registrarse/registrarse.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { HardcodedAutheticationService } from './../service/hardcoded-authetication.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContactanosComponent } from '../contactanos/contactanos.component';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  usuario:string;
  nombreBuscador:string
  cc:string
  nombre:string
  constructor(private router: Router,public autenticacion: HardcodedAutheticationService, public dialog: MatDialog) { }
  
  ngOnInit(): void {
    
    
  }

  abrirCampoBuscador(){
    this.router.navigate(['/eventos/buscador/',this.nombreBuscador])
  }
  cargarUsuario(){
    this.usuario=this.autenticacion.getUsuario();
    this.router.navigate(['/usuarios/usuario',this.usuario]);
  }

  cargarPromotor(){
    this.usuario = this.autenticacion.getPromotor();
    this.router.navigate(['promotor', this.usuario])
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(RegistrarseComponent, {
      width: '600px',
      height:'90%'
      
      
    });

    dialogRef.afterClosed().subscribe(result => {

      
    });
  }


  


  openDialog2(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',
 
      
    });

    dialogRef.afterClosed().subscribe(result => {
      
 
      
    });
  }

  openDialog3(): void {
    const dialogRef = this.dialog.open(ContactanosComponent, {
      width: '600px',
    
      
      
    });

    dialogRef.afterClosed().subscribe(result => {
  
      
    });
  }

  
}
