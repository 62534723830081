<section></section>
<header class="barra">
  <section>
    <!-- Header desktop -->
    <!-- Menu -->

    <div class="row m-0">

      <div class="col">
        <nav class="navbar navbar-expand-xl navbar-dark barra">

          <a routerLink='/home'>
            <img class="navbar-brand" src="../assets/images/img/logo2.webp" style="width:170px">
          </a>


          <div>
            <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#uno">
              <span class="navbar-toggler-icon "></span>
            </button>
          </div>



          <div class="collapse navbar-collapse justify-content-md-end" id="uno">
            <ul class="navbar-nav">



              <li routerLinkActive="active" class=" m-lg-3">
                <a routerLink='/nosotros' class="nav-link">Nosotros</a>
              </li>

              <li routerLinkActive="active" class=" m-lg-3">
                <a routerLink='/como-comprar' class="nav-link">¿Cómo comprar?</a>
              </li>



              <li routerLinkActive="active" class=" m-lg-3">
                <a (click)="openDialog3()" class="nav-link " style="cursor: pointer" class="nav-link">Contáctanos</a>
              </li>



              <li routerLinkActive="active" class=" m-lg-3">
                <a *ngIf="!autenticacion.getPromotor()&&!autenticacion.usuarioLoggin()"
                  (click)="openDialog2()" class="nav-link " style="color:#ed701c;cursor: pointer">Login</a> <a
                  *ngIf="autenticacion.usuarioLoggin()" routerLink='/usuarios/usuario/{{usuario}}' class="nav-link "
                  (click)="cargarUsuario()" style="color:#ed701c;"><mat-icon>person</mat-icon></a>
   
      
              </li>
              <li routerLinkActive="active" class=" m-lg-3"
                *ngIf="!autenticacion.getPromotor()&&!autenticacion.usuarioLoggin() ">
                <a (click)="openDialog()" class="nav-link " style="color:#ed701c;cursor: pointer">Regístrate</a>

              </li>

              <li routerLinkActive="active" class=" m-lg-3">
                <a *ngIf="autenticacion.usuarioLoggin()" routerLink='/logout'
                  class="nav-link"><mat-icon>exit_to_app</mat-icon></a>
                <a *ngIf="autenticacion.promotorLoggin()" routerLink='/logoutPromotor' class="nav-link">
                  <mat-icon>exit_to_app</mat-icon></a>
              </li>

              <li class="mt-4 mt-lg-0 mr-lg-3">
                <input type="text" placeholder="Buscar" class="form-control rounded-pill  m-lg-1 m-lg-3" required
                  [(ngModel)]="nombreBuscador">
              </li>
              <li class=" mt-4 mt-lg-0">
                <button class=" botonBarra btn m-lg-3 rounded border-dark shadow-sm" type="submit"
                  (click)="abrirCampoBuscador()">Buscar</button>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </section>
</header>