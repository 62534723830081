<section>
  <div class="img11">
    <div class="filtroNaranja1">
      <div class=" nombre1  "><strong>All Tickets</strong></div>

      <!-- <span class="pequeño1">
        <strong>
          Somos una empresa constituida en la Cámara de Comercio de Bogotá.
          NIT: 901265612-3
        </strong>
      </span>
      <a class="terminos" routerLink="terminosYcondiciones">Terminos y Condiciones</a> -->

    </div>
  </div>
</section>
<!-- <div id="prueba">


  <h1><strong>All Tickets</strong></h1>
  <h1 class="pequeño">
    <strong>
      Somos una empresa constituida en la Cámara de Comercio de Bogotá.
      NIT: 901265612-3
    </strong>
  </h1>
  <a class="terminos" routerLink="terminosYcondiciones">Terminos y Condiciones</a>

</div> -->

<div class="black">
  <section class="container mt-3">
    <div class="row">
      <div class="col-12 centrarTexto mb-3">

      </div>

      <div class="col-12 col-lg-4 contenedor8">

        <div class="tituloLocalidad rounded border">
          <div class="p-3">
            <h1 style="font-size: 28px;"><strong>¿Quiénes somos?</strong></h1>
          </div>
        </div>

        <div class="eventoFondo border">
          <p class="text-justify p-4">
            Somos All Tickets, una empresa colombiana dedicada a la venta de tickets electrónicos.
            ¡Mejoramos tu experiencia porque queremos que sólo te preocupes de disfrutar el evento al
            máximo!
            Por eso con nosotros puedes comprar tus tickets desde cualquier lugar, a cualquier momento y
            tenerlas al instante.
          </p>
        </div>



      </div>


      <div class="col-12 col-lg-4 contenedor8">
        <div class="tituloLocalidad rounded border">
          <div class="p-3">
            <h1 style="font-size: 28px;"><strong>Misión</strong></h1>
          </div>
        </div>

        <div class="eventoFondo border">
          <p class="text-justify p-4">
            Ser una tiquetera amigable para los usuarios y clientes, construyendo confianza por medio de la
            tecnología que implementamos.
            Brindamos un excelente servicio al cliente para la comodidad del mismo.
          </p>
        </div>

      </div>



      <div class="col-12 col-lg-4 contenedor8">
        <div class="tituloLocalidad rounded border">
          <div class="p-3">
            <h1 style="font-size: 28px;"><strong>Visión</strong></h1>
          </div>
        </div>

        <div class="eventoFondo border">
          <p class="text-justify p-4">
            Mantenernos como una tiquetera tecnológica y ágil, que brinde propuestas innovadoras
            revolucionando la boletería,
            aprovechando nuestras herramientas tecnológicas y adecuándonos a los cambios del mercado.
            Continuar posicionándonos en el mercado por nuestro enfoque en el servicio al cliente y
            transparencia en nuestra gestión.
          </p>
        </div>
      </div>


      <div class="col-4 contenedor8 mt-3">


        <div class="fondoLogo grid">
          <p class="mt-4">2018</p>
          <img src="../../assets/images/img/Antiguo Logo All Tickets.png" alt="">


        </div>
      </div>


      <div class="col-4 contenedor8 mt-5">


        <div class="fondoLogo grid mt-5">
          <img src="../../assets/images/img/flecha.png" alt="">
        </div>
      </div>

      <div class="col-4 contenedor8 mt-5">


        <div class="fondoLogo grid">

          <p>2021</p>
          <img src="../../assets/images/img/Logo naranja.png" alt="">

        </div>
      </div>

    </div>
  </section>
</div>