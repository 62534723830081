
<mat-icon class="float-right rounded pt-2 pb-2 pl-3 pr-3 botonesCerrar" mat-button mat-dialog-close> cancel_presentation</mat-icon>
<div class="container mt-3 mb-3">
<div class="row">
  <div class="col-1"></div>
  <div class="col-10 border" style="background-color: white;">
    <div class="centrarTexto mt-3">
      <h3 class="colorLetras"><strong>Contáctanos</strong></h3>
      <h5><strong>Estamos para resolver cualquier problema</strong></h5>
  </div>
  <hr>
    <form  class="p-4"  (ngSubmit)="!contactForm.invalid && onSubmit()" #contactForm="ngForm">
    
      <div class="form-group">
        
        <input [(ngModel)]="nombre"  required="required" name="nombre" type="text" id="nombre" class="form-control" placeholder="Nombre">
      </div>
  
  
  
      <div class="form-group">
        
        <input [(ngModel)]="correo"  required="required" name="correo" type="text" id="correo" class="form-control" placeholder="Correo">
      </div>
  
      <div class="form-group">
        
        <textarea [(ngModel)]="mensaje"  required="required" name="mensaje" style="height: 100px;" id="mensaje" cols="30" rows="10" placeholder="Mensaje" class="form-control"></textarea>
      </div>
  
      <button class="btn botonLogin" type="submit">Enviar</button>
  
    </form>
  </div>
  <div class="col-1"></div>
  </div>    
  
  </div>


  <section class="container redes">
    <div class="row">
        <div class="col-12">
            <a href="https://wa.link/3hg6ro"> <img class="imagenesRedes " src="./../../assets/images/img/logow.png" alt=""></a>

        </div>
    </div>
</section>