
<section>
  <div class="img11">
  
    <div class="filtroNaranja1">
      <h1 class="centrado4 rounded nombre1  "><strong>Reserva para el evento {{boletas[0]?.nombreEvento}}</strong></h1>

    </div>
  </div>
</section>




<div class="pagar cetrarTexto container border mt-5 p-3" *ngIf="!cargando">
    <p><strong>Nombre de la Reserva:</strong> {{usuario.nombre}}</p>
    <p><strong>Nombre del promotor:</strong> {{promotor.nombre}}</p>
    <p><strong>Localidad: </strong>{{localidad?.nombre}}</p>
    <p><strong>Numero Boletas: </strong>{{boletas.length}}</p>
    <p><strong>Valor Total: </strong>{{valorBase | currency:'COP'}}</p>

    <div class="bgcupon" *ngIf="localidad.vaca">
      <h3 class="font-vaca">¿Cuál es el monto qué deseas aportar a tu alcancía? (mínimo ${{localidad.pagoMinimo|number:'1.0-0'}})</h3>
      <form #todoForm="ngForm" class="w100">
        <fieldset class="form-group">
          <input type="number"
          name="valorVaca"
          required="required"
          class="input-vaca form-control"
          placeholder="Valor minimo $30.000"
           [(ngModel)]="valorTotal"
          >
        </fieldset>
      </form>

      <p><strong>Valor a pagar: </strong>{{valorTotal | currency:'COP'}}</p>
    </div>

    <button  class="btn action_button btnAplicar2" (click)="ptp()">PAGAR</button>
     
</div>


<section *ngIf="cargando">
    <div class="container-fluid contendor-spinner-2" >
      <div class="spinner"></div>
    </div>
  </section>