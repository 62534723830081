<section>
  <div class="img11">
    <div class="filtroNaranja1">
      <h1 class="centrado4 rounded nombre1  "><strong>¿Comó comprar?</strong></h1>
    </div>
  </div>
</section>

<div class="container centrarTexto">

  <div class="row">
    <div class="contTitulos mt-3">
      <div class="titulos">
        <h2><strong>Videos Guía</strong></h2>
      </div>
    </div>
    <section class="col-12  metodos">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Cómo crear una cuenta</p>
        </div>
      </div>
      <iframe class="videoInfo" width="560" height="315" src="https://www.youtube.com/embed/Dveoe8gLkXA"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </section>
    <section class="col-12  metodos">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Cómo comprar un ticket</p>
        </div>
      </div>
      <iframe class="videoInfo" width="560" height="315" src="https://www.youtube.com/embed/U4ZoFGW1838"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </section>
    <section class="col-12  metodos">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Cómo comprar un palco</p>
        </div>
      </div>
      <iframe class="videoInfo" width="560" height="315" src="https://www.youtube.com/embed/f879BEh0_Bw"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </section>
    <section class="col-12  metodos">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Cómo agregar personas a tu palco</p>
        </div>
      </div>
      <iframe class="videoInfo" width="560" height="315" src="https://www.youtube.com/embed/RS62I4fKAgE"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </section>
    <section class="col-12  metodos">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Cómo comprar adiciones a tu palco</p>
        </div>
      </div>
      <iframe class="videoInfo" width="560" height="315" src="https://www.youtube.com/embed/_wkY82qUYHE"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </section>
    <section class="col-12  metodos">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Cómo ceder tu ticket a otra persona</p>
        </div>
      </div>
      <iframe class="videoInfo" width="560" height="315" src="https://www.youtube.com/embed/SAcGfO317do"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </section>
    <section class="col-12  metodos">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Cómo reenviarte tu código QR</p>
        </div>
      </div>
      <iframe class="videoInfo" width="560" height="315" src="https://www.youtube.com/embed/Q1uf5CZxbxQ"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </section>

    <div class="contTitulos mt-3">
      <div class="titulos">
        <h2><strong>Métodos de pago</strong></h2>
      </div>
    </div>

    <section class="col-12  metodos">
      <img src="../../assets/images/img/AM.png" alt="">
      <img src="../../assets/images/img/CR.png" alt="">
      <img src="../../assets/images/img/VS.png" alt="">
      <img src="../../assets/images/img/MC.png" alt="">
      <img src="../../assets/images/img/DC.png" alt="">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Tarjetas de crédito</p>
        </div>
      </div>
    </section>

  </div>
  <div class="row">

    <section class="col-12  metodos">
      <img src="../../assets/images/img/VS.png" alt="">
      <img src="../../assets/images/img/MC.png" alt="">
      <img src="../../assets/images/img/PSE.png" alt="">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Tarjetas débito</p>
        </div>
      </div>
    </section>

    <section class="col-12  metodos">
      <img src="../../assets/images/img/EF.png" alt="">
      <img src="../../assets/images/img/PR.png" alt="">
      <img src="../../assets/images/img/BA.png" alt="">
      <img src="../../assets/images/img/GA.png" alt="">
      <img src="../../assets/images/img/RS.png" alt="">
      <div class="contTitulos">
        <div class="subtitulos">
          <p>Efectivo (Depende del monto)</p>
        </div>
      </div>
    </section>

  </div>
</div>

<section class="container redes">
  <div class="row">
    <div class="col-12">
      <a href="https://wa.link/3hg6ro"> <img class="imagenesRedes " src="./../../assets/images/img/logow.png"
          alt=""></a>

    </div>
  </div>
</section>