<section >

    <div id="prueba">


        <h1 class="centrado4 rounded" ><strong>Reserva para el evento {{palco?.nombreEvento}}</strong></h1>


    </div>
</section>


<div class="pagar cetrarTexto container border mt-5 p-3" *ngIf="!pagar2">
    <p><strong>Nombre de la Reserva:</strong> {{usuario.nombre}}</p>
    <p><strong>Nombre del promotor:</strong> {{promotor.nombre}}</p>
    <p><strong>Palco Numero: </strong>{{palco?.numeroDentroDeEvento}}</p>
    <p><strong>Valor Total: </strong>{{palco?.precio + palco?.servicio + palco?.servicioIva | currency:'COP'}}</p>
    <div class="ml-4" >
        <h5><strong>Selecciona el valor para aportar. Recuerda que debes reservar mínimo con el 50%, y completar el 100% una semana antes de comenzar el evento.</strong></h5>
        <select class="float-center ml-4 mb-4" (change)="cambiarTotal()" type="text" class= "form-control "name ="tipo" required="required" style="width: 100px;" [(ngModel)]="porcentaje">
            <option value=""> </option>

            <option value=30>30%</option>
            <option value=40>40%</option>
            <option value=50>50%</option>
            <option value=60>60%</option>
            <option value=70>70%</option>
            <option value=80>80%</option>
            <option value=90>90%</option>
            <option value=100>100%</option>
        </select>
        <h5 class="mt-4"><strong>Dinero a Aportar: </strong>{{valorAPagar | currency:'COP'}} </h5>
    </div>


      <button class="btn action_button" *ngIf="palco.id!=null" (click)="ptp()">
          Pagar
      </button>

</div>


<section *ngIf="pagar2">
    <div class="container-fluid contendor-spinner-2" >
      <div class="spinner"></div>
    </div>
  </section>
