<section>
  <div class="img11">
    <div class="filtroNaranja1">
      <h1 class="centrado4 rounded nombre1  "><strong>Eventos</strong></h1>
    </div>
  </div>
</section>


<section class="container-fluid " style="margin-top: 0%; margin-bottom: 5%; text-align: center;">
  <div class="row ">

    <div class="mt-md-3 col-6 col-sm-6  col-md-6  pl-sm-5 pr-sm-5 pl-md-2 pr-md-2 col-lg-4 col-xl-3 contenedor8"
      *ngFor="let evento of eventos">
      <div class="hover-overlay border rounded imgagenperfil" style="background-color: black; width: 100%;">

        <div class="contenedor contenedor2">
          <img *ngIf="evento.soldout" class="sold_out" src="../../assets/images/img/SOLDOUT.png" alt="">
          <a routerLink="/eventos/evento/{{evento.id}}" class="outerLink">
            <div class="oscurecer">
              <img src="{{evento?.imagen.url}}" *ngIf="evento.imagen" class=" rounded  darkableImage " />


            </div>
            <p class="centrado2"><strong> Compra ya tus Tickets para el evento {{evento.nombre}}</strong>

            </p>
          </a>
        </div>
      </div>

      <div class="border rounded container-fluid px-0 gris contenedorabajo"
        style="background-color: white; height: 50%">

        <div class="row contenedor contenedorBot">
          <div class="col-12 tituloEvento">
            <h2 class=" rounded titulos pt-3 pr-3 pl-3"><strong> <a class="titulos"
                  routerLink="/eventos/evento/{{evento.id}}">{{evento?.nombre}}</a></strong>
            </h2>
            <hr class="aaa">
          </div>


          <div class="col-12 col-sm-6 border-right fechaCiudad">
            <div *ngIf="evento?.id === 'ACK967' ">
              <p class="fechas "><strong>Fecha por confirmar</strong></p>
            </div>
            <div *ngIf="evento?.id !== 'ACK967' ">
              <p class="fechas "><strong>{{darFecha(evento)}}</strong></p>
            </div>
            <p class="fechas fechalugar">
              <strong>Ciudad: {{ evento?.id === 'ACK967' ? 'Pasto' : evento?.ciudadNombre }}</strong>
          </p>
          </div>

          <div class="col-12 col-sm-6 botonVer">
            <button *ngIf="!evento.soldout" class="action_button rounded-pill shadow texto bg-darkblue-gradient"
              routerLink="/eventos/evento/{{evento.id}}">Info.</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>





<app-redes-sociales></app-redes-sociales>
