
export const terminal=1166
export const terminalInternacional=1479

export const API_URL ="https://back.allticketscol.com"
export const API_URL2 ="https://back2.allticketscol.com"
export const API_URL3 ="http://localhost:5000"



export const respuesta ="https://allticketscol.com" 

export const IVA =0.19
