import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL, API_URL2, API_URL3, respuesta } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PtpDataService {

  constructor(private http: HttpClient) { }

  getPeticionPTP(idOrden,valorTotal,descripcion:string,tax){

    let url = respuesta +"/eventos/respuesta/"+idOrden;

    const formData: FormData= new FormData();
    formData.append('idOrden', idOrden);
    formData.append('valorTotal',valorTotal);
    formData.append('descripcion',descripcion);
    formData.append('url',url);
    formData.append('tax',tax);

    return this.http.post<any>(`${API_URL2}/ptp/crear-link`, formData);

  }
}
