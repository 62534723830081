import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL} from 'src/app/app.constants';
import { Promotor } from 'src/app/models/promotor.model';

@Injectable({
  providedIn: 'root'
})
export class PromotorDataService {

  constructor(private http:HttpClient) { }
  
  getAllPromotores(){
    return this.http.get<Promotor[]>((`${API_URL}/promotores`)); 
  }


  getAllPromotoresByEventoIdParaOrganizador(pIdEvento){
    return this.http.get((`${API_URL}/promotores/eventos/${pIdEvento}/organizador`)); 
  }

  getPromotorById(pId)
  {
    return this.http.get<Promotor>((`${API_URL}/promotores/${pId}`)); 
  }

  getPromotorByIdYEventos(pId)
  {
    return this.http.get<any>((`${API_URL}/promotores/eventos/agregar/${pId}`)); 
  }
  addPromotor(promotor:Promotor){
    return this.http.post<Promotor>((`${API_URL}/promotores`), promotor); 
  }

  updatePromotor(promotor:Promotor, pId){
    return this.http.put<Promotor>((`${API_URL}/promotores/${pId}`), promotor); 
  }
  cambiarPermisosPromotor( pId){

  
    return this.http.put<Promotor>((`${API_URL}/promotores/permiso/${pId}`), null); 
  }
  deletePromotorById(pId)
  {
    return this.http.delete<Promotor>((`${API_URL}/promotores/${pId}`)); 
  }


  getPromotorByUsuario(pUsuario)
  {
    return this.http.get<any>((`${API_URL}/promotores/perfil/usuario/${pUsuario}`)); 
  }

 
}
