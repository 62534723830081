

    <section class="container mt-5 ">
        <div class="row">
            
        <div class ="col-12 login  contenederoLogin">
            <mat-icon class="float-right rounded pt-2 pb-2 pl-3 pr-3 botonesCerrar" mat-button mat-dialog-close> cancel_presentation</mat-icon>
            <div class="fondoBlanco rounded shadow-lg">
                <div class=" pt-4 pb-4">
                    <h2 class="colorLetras"><strong>Iniciar Sesión</strong></h2>
                    <h5 class="azul"><strong>Tu Perfil All Tickets - All Products</strong></h5>
                </div>
         
                <form (ngSubmit)="handleLogin()" class="pl-4 pr-4">
                    <div class="mt-3  form-group">
                        
                        <input type="text" name= "username" class="rounded form-control"  placeholder="Correo" [(ngModel)]="usuario.usuario" >
                        <small class="colorLetras">Ingresa con tu correo</small>
                    </div>

                    

                    <div class="mt-3 form-group">
                        
                        <input type="password" name="password" class="rounded form-control" placeholder="Contraseña" [(ngModel)]="usuario.contrasena">
                    </div>
                    
                    
                
                    <div class="mt-3 mb-3 ">
                        <small *ngIf ='invalidLogin' class="text-danger" >{{errorMessage}}</small>
                        <br *ngIf ='invalidLogin'>
                        <button class="btn botonLogin mt-3"  type="submit">Iniciar Sesión</button>
                    </div>
                </form>
                <div class="centrarTexto">
                    <a class="colorLetras" (click)="olvidoContrasenia()">¿Olvidaste tu contraseña?</a>
                </div>
                <hr class="L75">
            
                <button class="btn botonCrearCuenta mb-5" (click)="openDialog()" type="submit">Crear cuenta nueva</button>
            </div>
        </div>
        
    </div>
    </section>
 

