import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { PromotorDataService } from '../service/data/promotor-data.service';
import { UsuariosDataService } from '../service/data/usuarios-data.service';
import { HardcodedAutheticationService } from '../service/hardcoded-authetication.service';
import { ReservasBoletasService } from '../service/data/reservas-boletas.service'; 
import { ReservaBoletas } from '../promotor-perfil/evento-promotor/localidad-promotor/reservar/reservaBoleta';
import { Promotor } from 'src/app/models/promotor.model';
import { Cliente } from '../usuario/cliente.model';
import { Boleta } from '../eventos/boleta.model';
import { BoletasDataService } from '../service/data/boletas-data.service';
import { Localidad } from '../models/localidad.model';
import { Evento } from '../eventos/evento.model';
import { LoginComponent } from '../login/login.component';
import { API_URL, respuesta, terminal, terminalInternacional } from '../app.constants';
import { MensajeComponent } from '../mensaje/mensaje.component';
import { OrdenDataService } from '../service/data/orden-data.service';
import { PtpDataService } from '../service/data/ptp-data.service';

@Component({
  selector: 'app-reserva-boleta',
  templateUrl: './reserva-boleta.component.html',
  styleUrls: ['./reserva-boleta.component.css']
})
export class ReservaBoletaComponent implements OnInit {

  descripcion:string
  terminal
  terminalInternacional
  respuesta
  miId
  user
  reserva:ReservaBoletas
  usuario: Cliente
  promotor: Promotor
  pagar:boolean
  boletas:Boleta[]

  valorTotal:number
  localidad:Localidad
  evento:Evento
  tax:number
  url: string
  codigoOrden
  idsBoletas:string
  valorBase:number
  cargando:boolean
  constructor(
    private autenticador: HardcodedAutheticationService
    , private route: ActivatedRoute,
    private clienteService: UsuariosDataService,
    private reservasService: ReservasBoletasService ,
    private promotorService: PromotorDataService,
    private dialog: MatDialog,
    private servicioBoletas: BoletasDataService,
    private ordenService: OrdenDataService,
    private ptpService:PtpDataService
  ) { }

  ngOnInit(): void {
    this.cargando=true
    this.idsBoletas=""
    this.url = 'https://merchant.paymentsway.co/cartaspago/redirect'
    this.terminal = terminal
    this.terminalInternacional = terminalInternacional
    this.respuesta = respuesta
    this.tax=0
    this.evento = new Evento();
    this.valorTotal=0

    this.boletas=[]
    this.pagar = true
    this.usuario = {
      nombre: "",
      numeroDocumento: null,
      tipoDocumento: "",
      usuario: "",
      contrasena: "",
      celular: null,
      correo: "",
      
      publicidad: null,
 
      tipo:null
    }
    this.promotor = new Promotor();
    this.localidad = new Localidad();
    this.reserva ={
      id:null,
      cantidad:null,
      numeroDocumentoReserva:null,
      localidad:null,
      usado:null,
      promotorDocumento:null
    }
    this.user = this.autenticador.getUsuario();
    if(this.user){
    this.route.paramMap.subscribe(params => {
      this.miId = params.get("id")
      this.reservasService.getReservaPorId(this.miId).subscribe(response=>{
        this.reserva = response.reserva
        this.promotor = response.promotor
        this.evento = response.evento
        this.localidad = response.localidad
        
       
        this.clienteService.getCliente(this.user).subscribe((response) => {
    
          this.usuario = response
          if(this.reserva.numeroDocumentoReserva == this.usuario.numeroDocumento){
            this.pagar = true

              




              this.servicioBoletas.reservarBoletaLocalidad(this.evento.id, this.localidad.id , this.reserva.cantidad).subscribe(response=>{
          
                if(response!=null){ 
                  this.boletas =response
                  this.descripcion= this.boletas.length +" Tickets para el evento " +this.evento.nombre  +" En la localidad "+this.boletas[0].localidadNombre
       

              for(var i=0; this.boletas.length>i;i=i+1)
              { 
        
                this.idsBoletas=this.idsBoletas + this.boletas[i].id+"_"
                this.valorTotal=this.valorTotal+ this.boletas[i].precio  +this.boletas[i].servicio +this.boletas[i].servicioIva
                this.tax =this.tax+ this.boletas[i].servicioIva 
                if(i == this.boletas.length-1){

                  this.idsBoletas=this.idsBoletas +"-1"
                }
      
              }
              this.valorBase= this.valorTotal
              if(!this.localidad.vaca){
                this.ordenService.addOrden(1,this.usuario.numeroDocumento,this.idsBoletas,-1,-1,this.promotor.codigoventa,0,-1).subscribe(response=>{
                  this.codigoOrden=response
                  this.pagar=false
                  this.cargando=false
                })     
              }
              else if(this.localidad.vaca){
                this.ordenService.addOrden(5,this.usuario.numeroDocumento,this.idsBoletas,-1,-1,this.promotor.codigoventa,0,-1).subscribe(response=>{
                  this.codigoOrden=response
                  this.pagar=false
                  this.cargando=false
                })  
              }
 
                  
              
            }
      
                else {
                  //alert("No quedan boletas en esta localidad, prueba más tarde")
                  this.openMensaje('No quedan boletas en esta localidad, prueba más tarde');
                }
                
              })
            
            
          }
          else{
            //alert("Esta reserva no esta a tu nombre")
            this.openMensaje('Esta reserva no esta a tu nombre');
          }
    
        })

      
 
    }
      )


  })
  }else{
    //alert("Debes ingresar a tu cuenta AllTickets para realizar la compra, en caso de no tener registraté")
    //this.openDialog2()
    this.openMensaje('Debes ingresar a tu cuenta AllTickets para realizar la compra, en caso de no tener, regístrate','openD');
  }

  }

  openMensaje(mensajeT:string,openD?:string):void{
    let screenWidth = screen.width;
    let anchoDialog:string = '500px';
    let anchomax:string = '80vw';
    let altoDialog:string = '250';
    if(screenWidth<=600){
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data:{
        mensaje:mensajeT
      }
    });
    if(openD=='openD'){
      dialogRef.afterClosed().subscribe((result) => {
        this.openDialog2();
      });
    }
  }


  openDialog2(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',


    });

    dialogRef.afterClosed().subscribe(result => {

      this.ngOnInit()

    });
  }



  ptp() {
    if (this.pagar == false) {
      if (!this.localidad.vaca) {

        this.pagar = true
        this.ptpService.getPeticionPTP(this.codigoOrden, this.valorTotal, this.descripcion,this.tax).subscribe({next:response => {
         window.location.href = response.processUrl;
        },error:error=>{
          this.openMensaje("Sucedio un error por favor vuleva a intentar")
          this.pagar=false
        }})
      }
      else {

        if (this.valorTotal > this.valorBase) {

          const valorNumerico = this.valorBase;
          const valorFormateado = valorNumerico.toLocaleString(); // Agrega puntos para separar los miles
          const mensajeFormateado = `El valor máximo a ingresar es de $${valorFormateado}`;
          this.openMensaje(mensajeFormateado)
        }
        else if (this.valorTotal < this.localidad.pagoMinimo) {

          const valorNumerico = this.localidad.pagoMinimo;
          const valorFormateado = valorNumerico.toLocaleString(); // Agrega puntos para separar los miles
          const mensajeFormateado = `El valor mínimo a ingresar es de $${valorFormateado}`;
          this.openMensaje(mensajeFormateado)
        }
        else {
          this.pagar = true
          this.ptpService.getPeticionPTP(this.codigoOrden, this.valorTotal, this.descripcion,this.tax).subscribe(response => {
            
            window.location.href = response.processUrl;
          })
        }

      }

    }

  }

}