import { Palco } from './palco.model';
import { Boleta } from '../eventos/boleta.model';
import { Adicionales } from './adicionales.model';
export class Localidad {
    id:number;
    nombre: string;
    precio:number;

    servicio:number;
    nombreEtapa:string;
    boletasPatrocinio:Boleta[];
    palcos:Palco[];
    servicioPorcentaje:number;
    efectivo:boolean;
    maximoVender:number;
    boletas:Boleta[];
    vaca:boolean;
    visibleMin:boolean;
    precioMin:number;
    vendidoMin:number;
	soldout:boolean;
    precioProximaEtapa:number;
    pagoMinimo:number;

}
