import { Etapa } from './eventos-perfil/etapa.model';
import { Palco } from '../models/palco.model';
import { Localidad } from '../models/localidad.model';
import { Foto } from '../models/foto.model';
import { Adicionales } from '../models/adicionales.model';
import { LocalidadProducto } from './localidadP.model';

export class Evento {
    id: string;
    nombre:string;
    fecha:Date;
    descripcion:string;
    lugar:string;
    video:string;
    terminosYCondiciones:string;
    recomendaciones:string;
    ciudadIdTexto:number;
    imagen:Foto;
    eventoQR:Foto;
    eventoWa:Foto;

    imagenes:Foto[];
    artistas;
    fechaFin:Date;
    mapa: string;
    localidades:Localidad[];    
    horaInicio:string;
    horaApertura:string;
    horaFin:string;
    etapas:Etapa[];
    mapaImagen:Foto;
    visible:boolean;
    soldout:boolean;
    mensaje:string;
    imagenFinal:Foto;
    fechaApertura:Date;
    urlMapa:string;
    oculto:boolean;
    dineroEntregado:number;
    ciudadNombre:string;
    localidadesProducto:LocalidadProducto[];
    visibleAP:boolean;
    visibleAC:boolean;
    visibleAS:boolean;
    terminado:boolean;
    imprimir:boolean;
    visibleMin:boolean;
    tipo:string;
    parafiscal:number;
    adicionales:Adicionales[]
    fechaCambioEtapa:Date;
}