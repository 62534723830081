import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import {GoogleTagManagerService} from 'angular-google-tag-manager';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  eventoId: string = 'Prue123'; // Suponiendo que obtienes este ID de alguna parte de tu aplicación

  get ogImage(): string {
    return this.eventoId === 'AT2024-34'
      ? 'https://allticketscol.com/assets/images/img/ufcImg.jpg'
      : 'https://allticketscol.com/assets/images/img/logo.png';
  }
  title = 'front-allTickets';
  constructor() {}
}
