<section class="container redes">
  <div class="row">
    <div class="col-12">
      <a href="https://api.whatsapp.com/send?phone=573209644716&text=Hola%20All%20Tickets,%20me%20comunico%20con%20ustedes%C2%A0porque:"
        target="_blank"> <img class="imagenesRedes " src="./../../assets/images/img/logow.png" alt=""></a>

    </div>
  </div>

  <!-- <div class="row">
      <div class="col-12">
          <a href="https://api.whatsapp.com/send?phone=573209644716&text=Hola%20All%20Tickets,%20me%20comunico%20con%20ustedes%C2%A0porque:" target="_blank"> <img class="imagenesRedes " src="./../../assets/images/img/logow.png"
                  alt=""></a>

      </div>
  </div> -->


</section>