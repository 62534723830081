
<footer id="footer" class="mt-5">
  
  <div class="wrap">

    <div class="izquierda">
      <p id="logo">
        <img src="../../assets/images/img/Logo grande.png" alt="">
      </p>
    </div>
      <div id="info">

       
            <a href="https://api.whatsapp.com/send?phone=573209644716&text=Hola%20All%20Tickets,%20me%20comunico%20con%20ustedes%C2%A0porque:"><img src="./../../assets/images/img/logow.png" alt=""></a>
            <a href="https://www.instagram.com/all_tickets/"> <img  src="./../../assets/images/img/logoig.png" alt=""></a>
            <a href="https://www.facebook.com/allticketscol"> <img  src="./../../assets/images/img/logofb.png"  alt=""></a>
     

          <p >All Rights Reserved &copy; 2024</p>
          <a class="terminos" href="https://codigos.allticketscol.com/TerminosYCondiciones.html"  target="_blank">Términos y Condiciones</a>
          <br>
          <a class="terminos" href="https://codigos.allticketscol.com/TratamientoDeDatos.html"  target="_blank">Tratamiento de Datos</a>
      </div>

  </div>
</footer>