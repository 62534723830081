import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Palco } from '../models/palco.model';
import {  API_URL2, respuesta, terminal } from '../app.constants';
import { LoginComponent } from '../login/login.component';
import { Reserva } from '../models/reserva.model';
import { Promotor } from 'src/app/models/promotor.model';
import { PromotorDataService } from '../service/data/promotor-data.service';
import { ReservasDataService } from '../service/data/reservas-data.service';
import { UsuariosDataService } from '../service/data/usuarios-data.service';
import { HardcodedAutheticationService } from '../service/hardcoded-authetication.service';
import { Cliente } from '../usuario/cliente.model';
import { Evento } from '../eventos/evento.model';
import { OrdenDataService } from '../service/data/orden-data.service';
import { MensajeComponent } from '../mensaje/mensaje.component';
import { PtpDataService } from '../service/data/ptp-data.service';

@Component({
  selector: 'app-reservas-promotor',
  templateUrl: './reservas-promotor.component.html',
  styleUrls: ['./reservas-promotor.component.css']
})
export class ReservasPromotorComponent implements OnInit {
  url: string
  respuesta: string
  reserva: Reserva
  usuario: Cliente
  user;
  miId
  pagar: boolean
  promotor: Promotor
  palco: Palco
  pagar2: boolean
  porcentaje
  valorAPagar
  tax: number
  evento: Evento;
  terminal
  descripcion: string
  terminalInternacional
  codigoOrden: any
  constructor(
    private autenticador: HardcodedAutheticationService
    , private route: ActivatedRoute,
    private clienteService: UsuariosDataService,
    private reservasService: ReservasDataService,
    private promotorService: PromotorDataService,
    private dialog: MatDialog,
    private ordenService: OrdenDataService,
    private ptpService: PtpDataService
  ) { }

  ngOnInit(): void {

    this.respuesta = respuesta
    this.terminalInternacional = this.terminalInternacional
    this.url = 'https://merchant.paymentsway.co/cartaspago/redirect'
    this.tax = 0
    this.terminal = terminal
    this.evento = new Evento();

    this.pagar = false
    this.pagar2 = true
    this.usuario = {
      nombre: "",
      numeroDocumento: null,
      tipoDocumento: "",
      usuario: "",
      contrasena: "",
      celular: null,
      correo: "",

      publicidad: null,

      tipo: null
    }

    this.reserva = {
      creationDate: null,
      documentoReserva: null,
      id: null,
      numeroPromotor: null
    }
    this.palco = {
      id: null,
      nombre: null,
      nombreEvento: null,
      personasAdentro: null,
      personasMaximas: null,
      precio: null,
      precioParcialPagado: null,
      reservado: null,
      servicio: null,
      vendido: null,
      numeroDentroDeEvento: null,
      fechaVendido: null,
      servicioIva: null,
      proceso: null,
      disponible: null,
      idLocalidad: null,
      reserva: null,
      precioAlterno: null,
      servicioAlterno: null,
      servicioIvaAlterno: null,
      adiciones: null,
      maximoAdiciones: null,
      precioAdicion: null,
      servicioAdicion: null,
      servicioIvaAdicion: null,
      metodo: null
    };

    this.promotor = new Promotor();

    this.route.paramMap.subscribe(params => {
      this.miId = params.get("id")
      this.reservasService.getReserva(this.miId).subscribe((response) => {
        this.reserva = response

        if (this.reserva == null) {
          //alert("Esta reserva no existe")
          this.openMensaje('Esta reserva no existe');
        }

        this.user = this.autenticador.getUsuario();
        if (this.user) {
          this.clienteService.getCliente(this.user).subscribe((response) => {

            this.usuario = response
            if (this.reserva.documentoReserva == this.usuario.numeroDocumento) {

              this.promotorService.getPromotorById(this.reserva.numeroPromotor).subscribe((response) => {
                this.promotor = response
                this.reservasService.getPalcoReserva(this.miId).subscribe((response) => {
                  this.palco = response
                  this.descripcion = "Aporte al palco " + this.palco.numeroDentroDeEvento
           
                  this.valorAPagar = this.palco.precio + this.palco.servicio + this.palco.servicioIva
                  this.reservasService.getEventoReserva(this.miId).subscribe(response => {

                    this.evento = response;


                    this.ordenService.addOrden(2, this.usuario.numeroDocumento, "_1", this.palco.id, -1, this.promotor.codigoventa, 0,-1).subscribe(response => {
                      this.codigoOrden = response
                      this.pagar2 = false
                    })



                  })

                })

              })
            }
            else {
              //alert("Esta reserva no esta a tu nombre")
              this.openMensaje('Esta reserva no esta a tu nombre');
            }

          })
        }



        else {
          //alert("Debes ingresar a tu cuenta AllTickets para realizar la compra, en caso de no tener registraté")
          //this.openDialog2()
          this.openMensaje('Debes ingresar a tu cuenta AllTickets para realizar la compra, en caso de no tener, regístrate', 'openD');

        }
      }
      )

    })
  }




  openDialog2(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '600px',


    });

    dialogRef.afterClosed().subscribe(result => {

      this.ngOnInit()

    });
  }

  openMensaje(mensajeT: string, openD?: string): void {
    let screenWidth = screen.width;
    let anchoDialog: string = '500px';
    let anchomax: string = '80vw';
    let altoDialog: string = '250';
    if (screenWidth <= 600) {
      anchoDialog = '100%';
      anchomax = '100%';
      altoDialog = 'auto';
    }
    const dialogRef = this.dialog.open(MensajeComponent, {
      width: anchoDialog,
      maxWidth: anchomax,
      height: altoDialog,
      data: {
        mensaje: mensajeT
      }
    });
    if (openD == 'openD') {
      dialogRef.afterClosed().subscribe((result) => {
        this.openDialog2();
      });
    } else if (openD == 'onInit') {
      this.ngOnInit();
    }
  }

  cambiarTotal() {

    this.valorAPagar = (this.porcentaje / 100) * (this.palco.precio + this.palco.servicio + this.palco.servicioIva);
    if (this.valorAPagar + this.palco.precioParcialPagado <= (this.palco.precio + this.palco.servicio + this.palco.servicioIva)) {


    }
    else {
      //alert("Estas aportando más del valor total")
      this.openMensaje('Estas aportando más del valor total');
    }
  }

  onCloseEpaycoModal() {
    this.pagar2 = false

    //alert('Close ePayco Modal!');
    this.openMensaje('Close ePayco Modal!');

  }


  ptp() {
    if (this.pagar == false) {
  

        this.pagar = true
        this.ptpService.getPeticionPTP(this.codigoOrden, this.valorAPagar, this.descripcion,this.tax).subscribe({next:response => {
         window.location.href = response.processUrl;
        },error:error=>{
          this.openMensaje("Sucedio un error por favor vuleva a intentar")
          this.pagar=false
        }})
      
    

    }

  }

  
}