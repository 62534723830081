import { Injectable } from '@angular/core';
import { API_URL, API_URL2 } from './../../app.constants';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class OrdenDataService {

  constructor(private http: HttpClient) { }

  
  addOrden(tipo,idCliente,idBoletas,idPalco,cupon, codigoVenta,cantidadAdiciones,puntoFisico){
    
    return this.http.post(`${API_URL}/orden/${tipo}/${idCliente}/${idBoletas}/${idPalco}/${cupon}/${codigoVenta}/${cantidadAdiciones}/${puntoFisico}`,null); 
  }
  getRespuestaOrden(pIdOrden){
    return this.http.get<any>(`${API_URL2}/orden/respuesta/${pIdOrden}`); 
  }
 
  cambiarAdicion(id,adiciones){
    return this.http.get(`${API_URL}/orden/${id}/${adiciones}/adiciones`); 
  }

  agregarAdicionalCompra(idOrden,idAdicional){
    return this.http.put(`${API_URL}/orden/${idOrden}/adicionales/${idAdicional}`,null); 
  }

  agregarCondiciones(id, idCondiciones) {
    return this.http.get(`${API_URL}/orden/${id}/${idCondiciones}/condiciones`);
  }

  getOrdenesPorEventoYEstado(pIdEvento,pEstado){
    return this.http.get(`${API_URL}/orden/${pIdEvento}/estado/${pEstado}`); 
  }
  agregarSeguro(pIdOrden){
    return this.http.get(`${API_URL}/orden/seguro/${pIdOrden}`)
  }
}
