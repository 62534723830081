<section class="container p-3">
    <div class="col-12 centrar banner">
        <h4>Mensaje de All Tickets</h4>
        <hr class="aaaa mb-1">
    </div>
    <div class="row">
        <div class="col-12 centrar">
            
            
            <p class="mensaje" [innerHTML]="mensaje"></p>
            <button class="botonLogin mt-3 rounded" mat-button mat-dialog-close>OK</button>
        </div>
    </div>
</section>