<app-menu></app-menu>

<section class="contenido ">

  <router-outlet></router-outlet>

</section>



<app-footer></app-footer>